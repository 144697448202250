import axios from 'axios'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useRef, useState } from 'react'
import { Badge, Button, Form, Loading, Radio } from 'react-daisyui'
import { GaugeComponent } from 'react-gauge-component'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Pregunta, Tema } from '../../encuestado/interface'
import { Encuesta } from '../interface'
import styles from './Resultados.module.scss'

export default function Resultados() {
	const [loading, setLoading] = useState(true);
	const [encuesta, setEncuesta] = useState<Encuesta>({});
	const [temas, setTemas] = useState<Tema[]>([]);
	const { cuestionarioId, clienteId } = useParams()
	const [arcos, setArcos] = useState({});
	const [selectedTema, setSelectedTema] = useState<Tema | null>(null);
	const [showSidebar, setShowSidebar] = useState(false)
	const contenedorRef = useRef<HTMLDivElement>(null);

	const [verMasMap, setVerMasMap] = useState<boolean>(false);
	const [mostrarBotonMap, setMostrarBotonMap] = useState<{ [key: string]: boolean }>({});

	const descargarEncuesta = () => {
		setLoading(true)
		axios.get(`/api/encuestas/${clienteId}/${cuestionarioId}`)
			.then((response) => {
				setEncuesta(response.data);
			})
			.catch((error) => {
				Swal.fire({
					heightAuto: false,
					icon: 'error',
					title: 'Error al obtener la encuesta',
					text: error.response.data.message
				})
			})
			.finally(() => {
				setLoading(false);
			})
	}
	const descargarResultados = () => {
		axios.get(`/api/encuestas/${clienteId}/${cuestionarioId}/resultado-tema`)
			.then((response) => {
				setTemas(response.data);
			})
			.catch((error) => {
				Swal.fire({
					heightAuto: false,
					icon: 'error',
					title: 'Error al obtener los resultados',
					text: error.response.data.message
				})
			});
	}

	const dibujarTemas = () => {
		return temas.map((tema) => {
			return (
				<Button key={tema.id_tema} fullWidth color="ghost" onClick={() => seleccionarTema(tema)} className={[styles.btn_tema, tema.id_tema === selectedTema?.id_tema ? styles.active : '', preguntasPendientesTema(tema) ? '' : 'text-success'].join(' ')}>
					{tema.nombre_mostrable}
				</Button>
			)
		})
	}

	const seleccionarTema = (tema: any) => {
		const temaFiltrado = temas.find((t) => t.id_tema === tema.id_tema)
		setSelectedTema(temaFiltrado || null);
	}

	const preguntasPendientesTema = (tema: Tema) => {
		let preguntas_sin_responder = new Array<Pregunta>();
		if (tema && tema.preguntas) {
			tema?.preguntas.forEach(pregunta => {
				if (pregunta.respuesta_usuario == null && pregunta.no_aplica == null) {
					preguntas_sin_responder.push(pregunta);
				}
				if (pregunta.subpreguntas) {
					pregunta?.subpreguntas.forEach(subpregunta => {
						if (pregunta.respuesta_usuario && subpregunta.respuesta_usuario == null && subpregunta.no_aplica == null) {
							preguntas_sin_responder.push(subpregunta);
						}
					})
				}
			})
		}

		let total_preguntas_sin_responder = preguntas_sin_responder.length;
		if (total_preguntas_sin_responder > 0) {
			return true
		} else {
			return false
		}
	}

	const badgeColorEstado = (estado: number) => {
		const estados: { [key: number]: JSX.Element } = {
			1: <Badge color="warning">Pendiente de responder</Badge>,
			2: <Badge color="primary">Iniciado</Badge>,
			3: <Badge color="success">Entregado</Badge>,
		};

		return estados[estado] || '';
	};

	const obtenerColor = (numero: number) => {
		switch (true) {
			case (numero < 50):
				return '#EA4228';
			case (numero < 85):
				return '#F5CD19';
			case (numero <= 100):
				return '#5BE12C';
			default:
				return '#EA4228';
		}
	}

	useEffect(() => {
		descargarEncuesta();
	}, [])

	useEffect(() => {
		descargarResultados();
		setArcos({
			subArcs: [
				{
					limit: 50,
					color: '#EA4228',
					showTick: true
				},
				{
					limit: 85,
					color: '#F5CD19',
					showTick: true
				},
				{
					limit: 100,
					color: '#5BE12C',
					showTick: true
				},
			]
		});
	}, [encuesta])

	const renderParrafos = (text: string) => {
		return text.split('\n').map((str, index) => (
			<p className="mb-8 text-xl" key={index}>
				{str}
			</p>
		));
	};

	const evaluarMostrarBoton = (id: string, ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			const alturaReal = ref.current.scrollHeight;
			const alturaVisible = ref.current.clientHeight;
			setMostrarBotonMap(prev => ({ ...prev, [id]: alturaReal > alturaVisible }));
		}
	};

	useEffect(() => {
		if (encuesta) {
			const encuestaRef = document.querySelector(`#encuesta-${encuesta.id_estado}`) as HTMLDivElement;
			if (encuestaRef) {
				evaluarMostrarBoton(`encuesta-${encuesta.id_estado}`, { current: encuestaRef });
			}
		}
		temas.forEach((resultado: Tema) => {
			const temaRef = document.querySelector(`#tema-${resultado.id_tema}`) as HTMLDivElement;
			if (temaRef) {
				evaluarMostrarBoton(`tema-${resultado.id_tema}`, { current: temaRef });
			}
		});
	}, [encuesta, temas]);

	return (
		<div className={styles.encuestas_wrapper}>
			<div className={styles.encuestas_container}>
				<div className={styles.encuestas}>
					{loading ? <div className={styles.loading}><Loading color="primary" /></div> :
						!selectedTema && <div className={styles.encuesta}>
							{encuesta.imagen &&
								<img className={styles.imagen} src={`/api/cuestionarios/imagen/${encuesta.imagen}`} alt="" />
							}
							<h2 className={styles.titulo}>{encuesta.nombre} {badgeColorEstado(Number(encuesta.id_estado))}</h2>
							<div
								ref={contenedorRef}
								className={`overflow-hidden ${Number(encuesta.id_estado) === 3 && !verMasMap ? "line-clamp-2" : ""}`}
								style={{ maxHeight: !verMasMap && Number(encuesta.id_estado) === 3 ? '4.5em' : 'none' }}>
								{renderParrafos(encuesta.descripcion ?? '')}
							</div>
							{Number(encuesta.id_estado) === 3 && mostrarBotonMap && (
								<button
									onClick={() => setVerMasMap(!verMasMap)}
									className="text-blue-500 mt-2 underline"
									style={{
										textAlign: 'right'
									}}
								>
									{verMasMap ? "Ver menos" : "Ver más"}
								</button>
							)}
							{Number(encuesta.id_estado) == 3 &&
								<div className={styles.graficos}>
									<GaugeComponent arc={arcos} value={encuesta.resultado} className={styles.grafico} />
									<HighchartsReact
										highcharts={Highcharts}
										options={{
											chart: {
												backgroundColor: 'transparent',
											},
											title: {
												text: 'Resultado por tema',
												style: {
													color: 'currentColor',
												}
											},
											xAxis: {
												type: 'category',
												labels: {
													style: {
														color: '#ffffff',
													},
												},
											},
											yAxis: {
												title: {
													text: 'Porcentaje de cada tema',
													style: {
														color: 'currentColor',
														font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
													}
												},
												labels: {
													style: {
														color: '#ffffff',
													},
												},
											},
											legend: {
												enabled: false
											},
											series: [{
												type: 'column',
												tooltip: {
													pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
														'<b>{point.y:.2f}%</b><br/>'
												},
												data: temas.map((tema) => {
													return {
														name: tema.nombre_mostrable,
														y: tema.resultado_tema,
														color: obtenerColor(tema.resultado_tema || 0)
													}
												})
											}]

										}}
									/>
									<HighchartsReact
										highcharts={Highcharts}
										options={{
											chart: {
												type: 'bar',
												backgroundColor: 'transparent'
											},
											title: {
												text: 'Resultado sobre el total',
												style: {
													color: 'currentColor',
												}
											},
											xAxis: {
												type: 'category',
												labels: {
													style: {
														color: '#ffffff',
													},
												},
											},
											yAxis: {
												title: {
													text: 'Porcentaje sobre el total',
													style: {
														color: 'currentColor',
														font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
													}
												},
												labels: {
													style: {
														color: '#ffffff',
													},
												},
											},
											legend: {
												enabled: false
											},
											series: [{
												type: 'column',
												tooltip: {
													pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
														'<b>{point.y:.2f}%</b><br/>'
												},
												data: temas.map((tema) => {
													return {
														name: tema.nombre_mostrable,
														y: tema.resultado_sobre_total,
														color: obtenerColor(tema.resultado_sobre_total || 0)
													}
												})
											}]

										}}
									/>
								</div>
							}
						</div>
					}
				</div>
				{((Number(encuesta.id_estado) == 2 || Number(encuesta.id_estado) == 3) && selectedTema) &&
					<div key={selectedTema?.id_tema} className={styles.tema}>
						<div className={styles.informacion}>
							<div>
								<h3 className={styles.titulo}>{selectedTema.nombre_mostrable}</h3>
								{renderParrafos(selectedTema.detalle ?? '')}
							</div>
							{Number(encuesta.id_estado) == 3 &&
								<GaugeComponent arc={arcos} value={selectedTema.resultado_tema} className={styles.grafico} />
							}
						</div>

						<ul className={styles.preguntas}>
							{selectedTema.preguntas?.map((pregunta, index) => (
								<div key={index}>
									<li key={index} className={styles.pregunta}>
										<span className={styles.numero}>
											{pregunta.orden}
										</span>
										{pregunta.texto_pregunta}
									</li>
									{pregunta.respuesta_libre && <div className="flex gap-2 mb-2"><strong>Respuesta:</strong> <p>{pregunta.respuesta_libre}</p></div>}
									<div className={styles.respuestas_wrapper}>
										{!pregunta.informativa &&
											<Form className={styles.respuestas}>
												<Form.Label title="Si">
													<Radio color={(Number(encuesta.id_estado) !== 3 || pregunta.no_aplica) ? "primary" : (pregunta.respuesta_usuario === pregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${pregunta.id}`} defaultChecked={pregunta.respuesta_usuario ? true : false} disabled />
												</Form.Label>
												<Form.Label title="No">
													<Radio color={(Number(encuesta.id_estado) !== 3 || pregunta.no_aplica) ? "primary" : (pregunta.respuesta_usuario === pregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${pregunta.id}`} defaultChecked={!pregunta.respuesta_usuario && pregunta.respuesta_usuario !== null ? true : false} disabled />
												</Form.Label>
												{pregunta.permite_no_aplica &&
													<Form.Label title="No Aplica">
														<Radio color="primary" size="sm" name={`radio-${pregunta.id}`} defaultChecked={pregunta.no_aplica ? true : false} disabled />
													</Form.Label>
												}
											</Form>
										}
									</div>
									{(pregunta.respuesta_usuario) &&
										<div className={styles.subpreguntas}>
											{pregunta.subpreguntas?.map((subpregunta, i) => (
												//Inicio de subpregunta
												<div style={{ marginLeft: '2.5rem' }} key={'sp-' + i}>
													<li key={'sp-' + i} className={styles.pregunta}>
														<span className={styles.numero}>
															{subpregunta.orden}.{subpregunta.sub_orden}
														</span>
														{subpregunta.texto_pregunta}
													</li>
													{subpregunta.respuesta_libre && <div className="flex gap-2 mb-2"><strong>Respuesta:</strong> <p>{subpregunta.respuesta_libre}</p></div>}
													<div className={styles.respuestas_wrapper}>
														{!subpregunta.informativa &&
															<Form className={styles.respuestas}>
																<Form.Label title="Si">
																	<Radio color={Number(encuesta.id_estado) !== 3 ? "primary" : (subpregunta.respuesta_usuario === subpregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${subpregunta.id}`} defaultChecked={subpregunta.respuesta_usuario ? true : false} disabled />
																</Form.Label>
																<Form.Label title="No">
																	<Radio color={Number(encuesta.id_estado) !== 3 ? "primary" : (subpregunta.respuesta_usuario === subpregunta.respuesta_correcta) ? "success" : "error"} size="sm" name={`radio-${subpregunta.id}`} defaultChecked={!subpregunta.respuesta_usuario && subpregunta.respuesta_usuario !== null ? true : false} disabled />
																</Form.Label>
																{subpregunta.permite_no_aplica &&
																	<Form.Label title="No Aplica">
																		<Radio color="primary" size="sm" name={`radio-${subpregunta.id}`} defaultChecked={subpregunta.no_aplica ? true : false} disabled />
																	</Form.Label>
																}
															</Form>
														}
													</div>
												</div>

												//Fin de subpregunta
											))}
										</div>
									}
								</div>

							))}
						</ul>
					</div>
				}
			</div>
			{(Number(encuesta.id_estado) == 2 || Number(encuesta.id_estado) == 3) &&
				<div style={{ position: 'relative' }}>
					<div className={[styles.sidebar_tema, showSidebar ? styles.show : ''].join(' ')}>
						<Button onClick={() => setSelectedTema(null)}>
							<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M240-200h120v-200q0-17 11.5-28.5T400-440h160q17 0 28.5 11.5T600-400v200h120v-360L480-740 240-560v360Zm-80 0v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H560q-17 0-28.5-11.5T520-160v-200h-80v200q0 17-11.5 28.5T400-120H240q-33 0-56.5-23.5T160-200Zm320-270Z" /></svg>
							Portada encuesta
						</Button>
						<div className={styles.temas}>
							{dibujarTemas()}
						</div>
					</div>
					<button onClick={() => setShowSidebar(!showSidebar)} className={styles.toggle}>
						{!showSidebar
							? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m432-480 156 156q11 11 11 28t-11 28q-11 11-28 11t-28-11L348-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q11-11 28-11t28 11q11 11 11 28t-11 28L432-480Z" /></svg>
							: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z" /></svg>
						}
					</button>
				</div>
			}
		</div>
	)

}