import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Avatar, Badge, Button, Input, Loading, Mask, Modal, Pagination, Select, Table, Tooltip } from "react-daisyui"
import Swal from "sweetalert2"
import { useDebouncedCallback } from "use-debounce"
import { Cuestionario } from "../cuestionarios/interface"
import { Cliente, Empresa } from "../empresas/interface"
import styles from "./Encuestas.module.scss"
import { Encuesta } from "./interface"

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import * as Yup from 'yup'

type EncuestasSchemaType = Yup.InferType<typeof encuestasSchema>;

const encuestasSchema = Yup.object().shape({
    empresa: Yup.string()
        .required('Campo requerido'),
    cliente: Yup.string()
        .required('Campo requerido'),
    cuestionario: Yup.string()
        .required('Campo requerido'),
});

export default function Encuestas() {
    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [encuestas, setEncuestas] = useState<Array<Encuesta>>([])
    const [encuesta, setEncuesta] = useState<Encuesta | null>(null)
    const [search, setSearch] = useState<string>('')
    const [estado, setEstado] = useState<string>('')
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)

    const [empresa, setEmpresa] = useState<Empresa | null>(null)
    const [empresas, setEmpresas] = useState<Array<Empresa>>([])

    const [cliente, setCliente] = useState<Cliente | null>(null)
    const [clientes, setClientes] = useState<Array<Cliente>>([])

    const [cuestionario, setCuestionario] = useState<Cuestionario | null>(null)
    const [cuestionarios, setCuestionarios] = useState<Array<Cuestionario>>([])
    const detailModal = useRef<HTMLDialogElement>(null);

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm<EncuestasSchemaType>({
        resolver: yupResolver(encuestasSchema)
    });


    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarEncuestas = () => {
        setLoading(true);
        const params = {
            offset: (page - 1) * limit,
            limit,
            ...(search && { "filters[search]": search }),
            ...(estado && { "filters[estado]": estado }) // Agregar filtro de estado si existe
        };
        axios.get('/api/encuestas', { params })
        .then((response) => {
            console.log(response.data.data);
            setEncuestas(response.data.data);
            setTotal(response.data.total);
        })
        .finally(() => {
            setLoading(false);
        });
    };


    const descargarEmpresas = () => {
        setSaveLoading(true);
        axios.get("/api/empresas").then((response) => {
            setEmpresas(response.data.data);
        }).finally(() => {
            setSaveLoading(false);
        })
    }

    const descargarClientes = () => {
        setSaveLoading(true);
        axios.get(`/api/empresas/${empresa?.id}/clientes`).then((response) => {
            setClientes(response.data.data);
        }).finally(() => {
            setSaveLoading(false);
        })
    }

    const descargarCuestionarios = () => {
        setSaveLoading(true);
        axios.get("/api/cuestionarios").then((response) => {
            setCuestionarios(response.data.data);
        }).finally(() => {
            setSaveLoading(false);
        })
    }

    const guardarEncuesta = () => {
        setSaveLoading(true);

        axios.post(`/api/encuestas/${cliente?.id}/${cuestionario?.id}`, {
            data: { ...encuesta }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'La encuesta se ha guardado correctamente'
            });
        }).catch((error) => {
            Swal.fire({
                heightAuto: false,
                icon: 'error',
                title: 'Error al guardar la encuesta',
                text: error.response.data.message
            })
        })
        .finally(() => {
            setSaveLoading(false);
            setEncuesta(null);
            setEmpresa(null);
            setCliente(null);
            setCuestionario(null);
            setOpen(false);
            detailModal.current?.close();
            reset();
            descargarEncuestas();
        });
    }

    const eliminarEncuesta = (encuesta: Encuesta) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará la encuesta de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/encuestas/${encuesta.id_cuestionario_cliente}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'La encuesta se ha eliminado correctamente'
                    });
                }).finally(() => {
                    descargarEncuestas();
                });
            }
        });
    }

    const verResultados = (encuesta: Encuesta) => {
        navigate(`/encuestas/${encuesta.id_cliente}/${encuesta.id_cuestionario_cliente}/resultados`)
    }

    useEffect(() => {
        reset();
        descargarEncuestas();
    }, [limit, page, search, estado]);

    const handleEstadoChange = (value: string) => {
        setEstado(value);
        setPage(1); // Reinicia la paginación al cambiar el filtro
    };

    useEffect(() => {
        descargarCuestionarios();
        descargarEmpresas();
    }, [open])

    useEffect(() => {
        if (empresa) {
            descargarClientes();
        }
    }, [empresa])

    const cerrarModal = () => {
        setEmpresa(null);
        setCliente(null);
        setCuestionario(null);
        setOpen(false);
        detailModal.current?.close();
        reset();
    };

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    const getBadgeColor = (value: number) => {
        if (value >= 0 && value <= 50) return 'error';
        if (value > 50 && value <= 85) return 'warning';
        if (value > 85) return 'success';
        return 'secondary';
    }

    const badgeColorEstado = (estado: number) => {
        const estados: { [key: number]: JSX.Element } = {
            1: <Badge color="warning">Pendiente de responder</Badge>,
            2: <Badge color="primary">Iniciado</Badge>,
            3: <Badge color="success">Entregado</Badge>,
        };

        return estados[estado] || '';
    };

    return (
        <div className={styles.encuestas_wrapper}>
            <div className={styles.actions}>
                <Input className='w-full' placeholder="Buscar..." size="sm" onChange={(e) => debounced(e.target.value)} />
                <Select
                    value={estado}
                    onChange={(e) => handleEstadoChange(e.target.value)}
                    className="w-48"
                    size="sm"
                >
                    <option value="">Todos los estados</option>
                    <option value="1">Pendiente de responder</option>
                    <option value="2">Iniciado</option>
                    <option value="3">Entregado</option>
                </Select>
                <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar encuesta</Button>
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="scrollbar-none h-full">
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span>Imágen</span>
                                <span>Cuestionario</span>
                                <span>Descripción</span>
                                <span>Cliente</span>
                                <span>Empresa</span>
                                <span>Estado</span>
                                <span>Resultado</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {encuestas.map((encuesta, item) => (
                                    <Table.Row key={item} hover>
                                        <span>
                                            {encuesta.imagen &&
                                                <Avatar size={'sm'} src={`/api/cuestionarios/imagen/${encuesta.imagen}`} innerClassName={Mask.className({
                                                    variant: 'squircle'
                                                })} />
                                            }
                                        </span>
                                        <span>{encuesta.nombre}</span>
                                        <span className="line-clamp">{encuesta.descripcion}</span>
                                        <span>{encuesta.cliente_apellido} {encuesta.cliente_nombre}</span>
                                        <span>{encuesta.empresa}</span>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {badgeColorEstado(Number(encuesta.id_estado))}
                                        </span>
                                        {encuesta.resultado != null ? (
                                            <Badge color={getBadgeColor(encuesta.resultado || 0)}>
                                                {Number(encuesta.resultado).toFixed(2)}%
                                            </Badge>
                                        ) : <></>}
                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                {(encuesta.id_estado && encuesta.id_estado != "1") &&
                                                    <Tooltip message="Resultados">
                                                        <Button size="sm" shape="square" color="primary" onClick={() => verResultados(encuesta)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-280h80v-200h-80v200Zm320 0h80v-400h-80v400Zm-160 0h80v-120h-80v120Zm0-200h80v-80h-80v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" /></svg>
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                <Tooltip message="Eliminar">
                                                    <Button size="sm" shape="square" color="error" onClick={() => eliminarEncuesta(encuesta)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>

            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small>Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>

            <Modal open={open} ref={detailModal}>
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">Agregar encuesta</Modal.Header>
                <Modal.Body>
                    {saveLoading ? <div className={styles.loading}><Loading color="primary" /></div> : <>
                        <form onSubmit={handleSubmit(guardarEncuesta)}>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Empresa</span>
                                </label>
                                <Select
                                    {...register('empresa')}
                                    color={errors.empresa ? 'error' : 'neutral'}
                                    className="input input-bordered"
                                    onChange={(e) => {
                                        const selectedEmpresa = empresas.find((empresa) => empresa.id.toString() === e.target.value);
                                        setEmpresa(selectedEmpresa || null);
                                        setValue('empresa', e.target.value);
                                    }}
                                >
                                    <option value="" key={'-'}>Seleccione...</option>
                                    {empresas.map((empresa) => (
                                        <option value={empresa.id} key={empresa.id}>{empresa.nombre}</option>
                                    ))}
                                </Select>
                                {errors.empresa && <span className="label-text-alt text-error">{errors.empresa.message}</span>}
                            </div>

                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Cliente</span>
                                </label>

                                <Select
                                    {...register('cliente')}
                                    color={errors.cliente ? 'error' : 'neutral'}
                                    className="input input-bordered"
                                    onChange={(e) => {
                                        const selectedCliente = clientes.find((cliente) => cliente.id !== undefined && cliente.id.toString() === e.target.value);
                                        setCliente(selectedCliente || null);
                                        setValue('cliente', e.target.value);
                                    }}
                                >
                                    <option value="" key={'-'}>Seleccione...</option>
                                    {clientes.map((cliente) => (
                                        <option value={cliente.id} key={cliente.id}>{cliente.apellido} {cliente.nombre}</option>
                                    ))}
                                </Select>
                                {errors.cliente && <span className="label-text-alt text-error">{errors.cliente.message}</span>}
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Cuestionario</span>
                                </label>
                                <Select
                                    {...register('cuestionario')}
                                    color={errors.cuestionario ? 'error' : 'neutral'}
                                    className="input input-bordered"
                                    onChange={(e) => {
                                        const selectedCuestionario = cuestionarios.find((cuestionario) => cuestionario?.id !== undefined && cuestionario?.id.toString() === e.target.value);
                                        setCuestionario(selectedCuestionario || null);
                                        setValue('cuestionario', e.target.value);
                                    }}
                                >
                                    <option value="" key={'-'}>Seleccione...</option>
                                    {cuestionarios.map((cuestionario) => (
                                        <option value={cuestionario?.id} key={cuestionario?.id}>{cuestionario.nombre}</option>
                                    ))}
                                </Select>
                                {errors.cuestionario && <span className="label-text-alt text-error">{errors.cuestionario.message}</span>}
                            </div>
                        </form>
                    </> }
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={guardarEncuesta}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}
